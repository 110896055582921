body {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
  font-weight: inherit;
}

.transparent {
  opacity: 0;
}
.black{
  top:0;
  left:0;
  width:100%;
  height:100%;
  position:fixed;
  background-color: black;
  z-index: 99;
}

.video-animation__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1
}

.unselectable {
  display: flex;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}